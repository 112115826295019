import {DemoCreate} from "./DemoCreate";


const my_form = document.getElementById('ntw_form') as HTMLFormElement;

if (my_form) {
    new DemoCreate();
}


