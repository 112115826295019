export class DemoCreate {

    my_form : HTMLFormElement;

    company_field: HTMLInputElement;
    first_name_field : HTMLInputElement;
    last_name_field : HTMLInputElement;
    email_field : HTMLInputElement;
    password_field : HTMLInputElement;
    password_confirmation_field : HTMLInputElement;
    subdomain_field : HTMLInputElement;
    source_field: HTMLInputElement;

    error_first_name : HTMLParagraphElement;
    error_last_name : HTMLParagraphElement;
    error_email : HTMLParagraphElement;
    error_password : HTMLParagraphElement;
    error_password_confirmation : HTMLParagraphElement;
    error_subdomain : HTMLParagraphElement;
    error_system: HTMLParagraphElement;

    processing: boolean = false;

    constructor() {
        this.my_form = document.getElementById('ntw_form') as HTMLFormElement;

        this.company_field = document.getElementById('company') as HTMLInputElement;
        this.first_name_field = document.getElementById('first_name') as HTMLInputElement;
        this.last_name_field = document.getElementById('last_name') as HTMLInputElement;
        this.email_field = document.getElementById('email') as HTMLInputElement;
        this.password_field = document.getElementById('password') as HTMLInputElement;
        this.password_confirmation_field = document.getElementById('password_confirmation') as HTMLInputElement;
        this.subdomain_field = document.getElementById('subdomain') as HTMLInputElement;
        this.source_field = document.getElementById('source') as HTMLInputElement;

        this.error_first_name = document.querySelector('#error_first_name p') as HTMLDivElement;
        this.error_last_name = document.querySelector('#error_last_name p') as HTMLDivElement;
        this.error_email = document.querySelector('#error_email p') as HTMLDivElement;
        this.error_password = document.querySelector('#error_password p') as HTMLDivElement;
        this.error_password_confirmation = document.querySelector('#error_password_confirmation p') as HTMLDivElement;
        this.error_subdomain = document.querySelector('#error_subdomain p') as HTMLDivElement;
        this.error_system = document.querySelector('#error_system p') as HTMLDivElement;
        this.my_form.addEventListener('submit', (e) => {
            e.preventDefault();
            if (!this.processing) {
                this.processing = true;
                let errorsStop = false;
                this.error_first_name.style.display = 'none';
                this.error_last_name.style.display = 'none';
                this.error_email.style.display = 'none';
                this.error_password.style.display = 'none';
                this.error_password_confirmation.style.display = 'none';
                this.error_subdomain.style.display = 'none';
                this.error_system.style.display = 'none';

                if (!errorsStop && this.company_field.value == '') {
                    this.loadData();
                }
            }
        });

    }


    loadData(): void {
        let url = `/?ntw_ajax_demo_create=1`;
        const data = {
            first_name: this.first_name_field.value,
            last_name: this.last_name_field.value,
            email: this.email_field.value,
            password: this.password_field.value,
            subdomain: this.subdomain_field.value,
            source: this.source_field.value
        };
        fetch(url,{
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => response.json()).then(data => {
            this.processing = false;
            if (data.status === 'success') {
                window.location.href = `https://${data.subdomain}.${data.endung_konto_addresse}/?token=${data.access_token}`;
                // console.log(`https://${data.subdomain}.${data.endung_konto_addresse}/?token=${data.access_token}`);
            } else {

                if (!data.errors) {
                    this.error_system.style.display = 'block';
                    this.error_system.innerText = 'Leider funktioniert die Verbindung zur API nicht, bitte versuchen Sie es später wieder oder kontaktieren unseren Support.';
                } else {
                    const errors_text : string[] = data.errors;
                    errors_text.forEach(error => {
                        const error_text : string = error;
                        if (error_text.startsWith('Vorname')) {
                            this.error_first_name.innerText = error_text;
                            this.error_first_name.style.display = 'block';
                        }
                        if (error_text.startsWith('Nachname')) {
                            this.error_last_name.innerText = error_text;
                            this.error_last_name.style.display = 'block';
                        }
                        if (error_text.startsWith('Email')) {
                            this.error_email.style.display = 'block';
                            if (this.email_field.value == '') {
                                this.error_email.innerText = 'E-Mail-Adresse muss ausgefüllt werden';
                            } else {
                                this.error_email.innerText = error_text;
                            }
                        }
                        if (error_text.startsWith('Passwort bestätigen')) {
                            this.error_password_confirmation.innerText = error_text;
                            this.error_password_confirmation.style.display = 'block';
                        } else if (error_text.startsWith('Passwort')) {
                            this.error_password.innerText = error_text;
                            this.error_password.style.display = 'block';
                        }
                        if (error_text.startsWith('Diese Subdomain') || error_text.startsWith('Subdomain')) {
                            this.error_subdomain.innerText = error.replace('\n',' ');
                            this.error_subdomain.style.display = 'block';
                        }
                        if (error_text.startsWith('Email')) {

                            this.error_email.innerText = error_text;
                            this.error_email.style.display = 'block';
                        }
                    })
                }

            }
            // window.location.href = `https://{$results['subdomain']}.my-test.at/?token={$results['access_token']}<p>`;
        })
    }

}